
import React, { Component } from 'react';

export default class MediaS extends Component {

	render() { 

        
		return (
			<div>            {/* Youtube */}
			<div id="media"></div>
            <div className="youtube-section">
            	<h2 className="normal-headline" id="videos">Media</h2>
<div class="elfsight-app-1d705adb-b893-48c8-8d5c-fd6bf881cc93"></div>		
<div className="soundcloud-section">     
<div className="row-1">
	<div className="item">
	<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/982090222&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iframe>
		<p className="fade-text-media" data-emergence="hidden"> Johannes Brahms (1833 - 1897): Da unten im Tale </p>
	</div>
	<div className="item">

	<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/982089046&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>

		<p className="fade-text-media" data-emergence="hidden"> Jean Sibelius (1865 - 1957): Våren flykta hastigt </p>
	</div>
	<div className="item">


	<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/982089547&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
		<p className="fade-text-media" data-emergence="hidden"> Wilhelm Stenhammar (1871 - 1927): Till en ros </p>
	</div>

</div>
<div className="row-2">
<div className="item">
<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/690874603&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>

	<p className="fade-text-media" data-emergence="hidden"> T. Merula (1595 - 1665): Folle e ben che si crede </p>
</div>
<div className="item">

<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/971750392&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
	<p className="fade-text-media" data-emergence="hidden"> Benjamin Thorn (*1961): The Voice Of The Crocodile </p>
</div>


</div>
</div>









</div>
		
         

</div>

		);
	}
}
