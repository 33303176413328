
import React from 'react';
import { withNamespaces } from 'react-i18next';

function Switch({ events, t, language }) {
	return (
		<div className="switch-section">
			<div className="switch-gradient">
			</div>

				<div className="switch-container switch-container-desktop">
					<div className="switch-grid">
						<div className="item">
							<a href="/floetistin">
							<h2>Blockflötistin</h2>
							</a>
							<div className="line"></div>
						</div>
						<div className="item">
						<a href="/sing">

							<h2>Mezzosopran</h2>
							</a>
							<div className="line"></div>
							</div>
					</div>
					<h1 className="pre-head fade-about" data-emergence="hidden">
					<span>L</span>
					<span>a</span>
					<span>u</span>
					<span>r</span>
					<span>a</span> 
					<span>&nbsp;</span>
					<span>K</span>
					<span>i</span>
					<span>e</span>
					<span>ß</span>
					<span>k</span>
					<span>a</span>
					<span>l</span>
					<span>t</span>
					
					</h1>
				</div>
				<div className="switch-container switch-container-mobile">
				<h1 className="pre-head fade-about" data-emergence="hidden">
					<span>L</span>
					<span>a</span>
					<span>u</span>
					<span>r</span>
					<span>a</span> 
					<span>&nbsp;</span>
					<span>K</span>
					<span>i</span>
					<span>e</span>
					<span>ß</span>
					<span>k</span>
					<span>a</span>
					<span>l</span>
					<span>t</span>
					
					</h1>
					<div className="switch-grid">
						<div className="item">
							<a href="/floetistin">
							<h2>Blockflötistin</h2>
							</a>
							<div className="line"></div>
						</div>
						<div className="item">
						<a href="/sing">

							<h2>Mezzosopran</h2>
							</a>
							<div className="line"></div>
							</div>
					</div>
				</div>
		</div>
	);
}

export default withNamespaces()(Switch);
