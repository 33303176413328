import React, { useState } from 'react';
import EventList from '../components/calendar/EventList';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import Event from '../components/calendar/Event';
import { withNamespaces } from 'react-i18next';

function Calendar({ events, t, language }) {
	const sortedEvents = events.sort((a, b) => moment(a.fields.begin).diff(b.fields.begin));
	const january21Events = [];
	const february21Events = [];
	const march21Events = [];
	const april21Events = [];
	const may21Events = [];
	const june21Events = []; 
	const july21Events = [];
	const august21Events = [];
	const september21Events = [];
	const october21Events = [];
	const november21Events = [];
	const december21Events = [];
	const january22Events = [];
	const february22Events = [];
	const march22Events = [];
	const april22Events = [];
	const may22Events = [];
	const june22Events = []; 
	const july22Events = [];
	const august22Events = [];
	const september22Events = [];
	const october22Events = [];
	const november22Events = [];
	const december22Events = [];

	const sortEvents = () => {
		sortedEvents.forEach((event) => {
			if (new Date(event.fields.begin).getFullYear() === 2023) {
			if (new Date(event.fields.begin).getMonth() === 0) {
				january21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 1) {
				february21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 2) {
				march21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 3) {
				april21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 4) {
				may21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 5) {
				june21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 6) {
				july21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 7) {
				august21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 8) {
				september21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 9) {
				october21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 10) {
				november21Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 11) {
				december21Events.push(event);
			}
		} else if (new Date(event.fields.begin).getFullYear() === 2024) {
			if (new Date(event.fields.begin).getMonth() === 0) {
				january22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 1) {
				february22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 2) {
				march22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 3) {
				april22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 4) {
				may22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 5) {
				june22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 6) {
				july22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 7) {
				august22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 8) {
				september22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 9) {
				october22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 10) {
				november22Events.push(event);
			} else if (new Date(event.fields.begin).getMonth() === 11) {
				december22Events.push(event);
			}
		}
		});
	};

	sortEvents();

	return (
		<div>
			<div id="calendar" />
			<div className="calendar-section">
				<div className="item-calendar">
					<h2 className="normal-headline">{t('Kalender')}</h2>
					<div className="calendar-grid">
						{/* 
						<EventList events={events}/>
						*/}

						{/* 2021 */}

						{january21Events.length > 0 ? (
							<Collapsible className="month" class trigger="Januar 2023">
								{january21Events.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{february21Events.length > 0 ? (
							<Collapsible className="month" class trigger="Februar 2023">
								{february21Events.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{march21Events.length > 0 ? (
							<Collapsible className="month" class trigger="März 2023">
								{march21Events.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{april21Events.length > 0 ? (
							<Collapsible className="month" class trigger="April 2023">
								{april21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{may21Events.length > 0 ? (
							<Collapsible className="month" class trigger="Mai 2023">
								{may21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{june21Events.length > 0 ? (
							<Collapsible className="month" class trigger="Juni 2023">
								{june21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{july21Events.length > 0 ? (
							<Collapsible className="month" class trigger="Juli 2023">
								{july21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{august21Events.length > 0 ? (
							<Collapsible className="month" class trigger="August 2023">
								{august21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{september21Events.length > 0 ? (
							<Collapsible className="month" class trigger="September 2023">
								{september21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{october21Events.length > 0 ? (
							<Collapsible className="month" class trigger="Oktober 2023">
								{october21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{november21Events.length > 0 ? (
							<Collapsible className="month" class trigger="November 2023">
								{november21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{december21Events.length > 0 ? (
							<Collapsible className="month" class trigger="Dezember 2023">
								{december21Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}


						{/* 2022 */}

						{january22Events.length > 0 ? (
							<Collapsible className="month" class trigger="Januar 2024">
								{january22Events.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{february22Events.length > 0 ? (
							<Collapsible className="month" class trigger="Februar 2024">
								{february22Events.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{march22Events.length > 0 ? (
							<Collapsible className="month" class trigger="März 2024">
								{march22Events.map((event) => {
									return <Event event={event} language={language}  />;
								})}
							</Collapsible>
						) : null}
						{april22Events.length > 0 ? (
							<Collapsible className="month" class trigger="April 2024">
								{april22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{may22Events.length > 0 ? (
							<Collapsible className="month" class trigger="Mai 2024">
								{may22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{june22Events.length > 0 ? (
							<Collapsible className="month" class trigger="Juni 2024">
								{june22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{july22Events.length > 0 ? (
							<Collapsible className="month" class trigger="Juli 2024">
								{july22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{august22Events.length > 0 ? (
							<Collapsible className="month" class trigger="August 2024">
								{august22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{september22Events.length > 0 ? (
							<Collapsible className="month" class trigger="September 2024">
								{september22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{october22Events.length > 0 ? (
							<Collapsible className="month" class trigger="Oktober 2024">
								{october22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{november22Events.length > 0 ? (
							<Collapsible className="month" class trigger="November 2024">
								{november22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}
						{december22Events.length > 0 ? (
							<Collapsible className="month" class trigger="Dezember 2024">
								{december22Events.map((event) => {
									return <Event event={event} language={language} />;
								})}
							</Collapsible>
						) : null}

						
{events.length === 0 ? (
				<div className="no-event-grid">
				<div className="no-events">{t('no upcoming events')}</div>
			</div>
			) : (
				<div>
					</div>
			)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withNamespaces()(Calendar);
