import React, { useEffect } from 'react';
import BioContent from '../components/bio/BioContent';
//import { gsap, TimelineLite, Power3 } from 'gsap';
//import { ScrollTrigger } from 'gsap/ScrollTrigger';
//gsap.registerPlugin(ScrollTrigger);

function Biography({ biography, language }) {
	console.log(biography);
	return (
		<div>
			<div id="bio" />
			<section className="animation-bi bio-f">
				<section className="animation-bio" id="animation-bio heroBg">
					<div className="trigger">
						<p className="textbio" id="heroText">
							<p className="inside-bio">
								{biography.slice(0, 1).map((bio, index) => {
									return <BioContent biography={bio} key={index} language={language} />;
								})}

								{/* <h2 className="normal-headline">Biografie</h2>
								<div className="bio-text">
									Laura Kießkalt beendete 2020 ihr Master Studium Blockflöte für Konzertfach bei Univ.
									Prof. Dorothee Oberlinger, Walter van Hauwe und Matthijs Lunenburg an der
									Universität Mozarteum Salzburg mit Bestnoten, nachdem sie dort ebenfalls mit
									Auszeichnung ihre Bachelorstudien in Konzertfach und Instrumentalpädagogik
									Blockflöte absolvierte.
									<br />
									Aktuell wurde die Blockflötistin mit einem hochdotierten Sonderstipendium der
									Deutschen Stiftung Musikleben ausgezeichnet. Sie ist neben dem Gewinn des
									internationalen Blockflötenwettbewerbs „Nordhorn 2013“ u.a. mehrfache
									Bundespreisträgerin des Wettbewerbs „Jugend musiziert“ und war neben europaweiten
									Konzertauftritten auch beim internationalen Festival „Musiquem Lleida“ in Spanien zu
									hören, wo ihrem Ensemble der Publikumspreis zugesprochen wurde. 2013 – 2015 war sie
									„Gerd Bucerius Stipendiatin“ der Deutschen Stiftung Musikleben, ist mehrfache
									Trägerin des Leistungsstipendiums der Universität Mozarteum und seit 2014
									Stipendiatin der Hildegard Schmalzl Stiftung der Stadt Regensburg. Meisterkurse
									führten sie unter anderem zu bekannten Musikern wie Maurice Steger, Hugo Reyne,
									Antonio Politano, Ashley Solomon, Jeremias Schwarzer u.v.m.
									<br />
									  Ausschnitte ihrer Konzertauftritte - u.a. von "Tage Alter Musik Regensburg" sowie
									"Tage Mitteldeutscher Barockmusik" waren bereits mehrmals bei den Radiosendern BR
									Klassik und mdr zu hören.
									<br />
									Laura Kießkalt absolviert derzeit ihr Master Studium Gesangpädagogik an der
									Universität Mozarteum Salzburg bei Regina Prasser, Andreas Scholl und Thérèse
									Lindquist. Mit Auszeichnung beendete sie zuvor den Bachelor und ist neben
									solistischen Auftritten auch Mitglied einiger renommierter Chöre, wie z.B. Chor der
									KlangVerwaltung und Salzburger Bachchor. Als Teil dieser Ensembles sang sie u.a. bei
									den Salzburger Festspielen, Salzburger Osterfestspielen, der Mozartwoche Salzburg
									und Augsburg, den Innsbrucker Festwochen und produzierte mehrere CD Aufnahmen mit
									den Rundfunkanstalten BR und ORF.
									<br />
									Über das von Laura Kießkalt entwickelte Konzertkonzept suonare e cantare, das
									Vokalwerke und Blockflötenliteratur mit Vokalbezug vereint, schreibt die bekannte
									Blockflötistin, Dirigentin und Intendantin Dorothee Oberlinger: „Die Rhetorik,
									sprechende Artikulation, singende Klangschönheit und Virtuosität ihres
									Blockflötenspiels stellt sie hier gesungenen Arien gegenüber, schlüpft in fließenden
									Übergängen jeweils in die solistische Rolle der Sängerin und Instrumentalistin und
									lässt beide Parts voneinander in idealer Weise profitieren.“
								</div> */}
							</p>
						</p>
					</div>
				</section>
			</section>
		</div>
	);
}

export default Biography;
