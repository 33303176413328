
import React from 'react';
import { withNamespaces } from 'react-i18next';

function ReferencesS({ language ,t }) {
	return (
		<div>
			<div id="references"></div>
		<div className="references-section">
		<div className="item">
			<h2>
			{t('referencesS')}
			</h2>
			
			<p>{t('referencesSsub')} </p>
			</div>
		</div>
		</div>
	);
}

export default withNamespaces()(ReferencesS);
