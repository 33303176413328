import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import $ from 'jquery';
import SimpleReactLightbox from 'simple-react-lightbox'


ReactDOM.render(
	<React.StrictMode>
		<Router>
    <SimpleReactLightbox>

			<App />
    </SimpleReactLightbox>

		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);


window.onload = function() {
  	/* SWITCH */
	var root = document.getElementById("root");
	root.classList.add("loaded");
}; 



	


$('.burger, .navbar-black .nav-content .nav-links .normal-link a ').click(function() {
	$('.navbar .nav-content .nav-links').toggleClass('nav-active');
	$('.navbar .nav-content .nav-links .normal-link').toggleClass('nav-active');
	$('.navbar').toggleClass('nav-active');
	$(this).toggleClass('burger-nav-active');
});


$('.navbar-black .nav-content .nav-links .normal-link a ').on('click', function(e) {
	if(this.hash !== ''){
	  e.preventDefault();
	  const hash = this.hash;

	  $('html, body').animate({
		scrollTop: $(hash).offset().top
	  }, 800);
	}
  });
  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
