import React from 'react';
import { withNamespaces } from 'react-i18next';


function Contact({ language ,t }) {
	return (
		
		<section className=" contact-section" id="contact">
				<section className="animation-contact fade-in-contactgradient" data-emergence="hidden" id="animation-bio" >
					<div className="trigger-contact">
						<div className="textcontact fade-in-contacttext" data-emergence="hidden">
							<p className="inside-bio">
							<h2 className="normal-headline">			{t('Kontakt')}
</h2>
							<div className="contact-item">
								
							<div className="contact-item">
						<div className="hover-box">
							<a href="mailto:laurakiesskalt.musik@gmail.com">
								<svg
									width="38"
									height="38"
									viewBox="0 0 38 38"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M34.6602 4.52753H3.33984C1.50055 4.52753 0 6.02645 0 7.86737V30.133C0 31.9677 1.49388 33.4728 3.33984 33.4728H34.6602C36.4948 33.4728 38 31.979 38 30.133V7.86737C38 6.03268 36.5061 4.52753 34.6602 4.52753ZM34.199 6.75409L19.0708 21.8823L3.8118 6.75409H34.199ZM2.22656 29.6719V8.3178L12.9496 18.9489L2.22656 29.6719ZM3.80096 31.2463L14.5308 20.5165L18.2905 24.2439C18.7257 24.6754 19.428 24.674 19.8615 24.2405L23.5273 20.5746L34.199 31.2463H3.80096ZM35.7734 29.6719L25.1017 19.0002L35.7734 8.32842V29.6719Z"
										fill="white"
									/>
								</svg>
							</a>

							<div className="center-link">
								<a href="mailto:laurakiesskalt.musik@gmail.com">laurakiesskalt.musik@gmail.com</a>
							</div>
						</div>
					</div>
					<div className="contact-item">

<a href="./img/Presskit-Mezzosopran.zip" className="bio-btn" download>
<svg
					height="512pt"
					viewBox="0 0 512 512"
					width="512pt"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0" />
				</svg>
				Presskit
				
			</a>
			</div>	
					<div className="contact-item">
						<div className="hover-box">
							<a href="https://de-de.facebook.com/Laura-Kie%C3%9Fkalt-Blockfl%C3%B6tistin-Mezzo-Sopran-545325259157089/" target="_blank" rel="noopener noreferrer">
								<svg
									width="30"
									height="30"
									viewBox="0 0 30 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M4.39453 30H14.1797V19.3359H10.6641V15.8203H14.1797V11.4258C14.1797 9.00261 16.151 7.03125 18.5742 7.03125H22.9687V10.5469H19.4531C18.4838 10.5469 17.6953 11.3354 17.6953 12.3047V15.8203H22.8101L22.2242 19.3359H17.6953V30H25.6055C28.0286 30 30 28.0286 30 25.6055V4.39453C30 1.97136 28.0286 0 25.6055 0H4.39453C1.97136 0 0 1.97136 0 4.39453V25.6055C0 28.0286 1.97136 30 4.39453 30ZM1.75781 4.39453C1.75781 2.94067 2.94067 1.75781 4.39453 1.75781H25.6055C27.0593 1.75781 28.2422 2.94067 28.2422 4.39453V25.6055C28.2422 27.0593 27.0593 28.2422 25.6055 28.2422H19.4531V21.0937H23.7133L24.8852 14.0625H19.4531V12.3047H24.7266V5.27344H18.5742C15.1817 5.27344 12.4219 8.03329 12.4219 11.4258V14.0625H8.90625V21.0937H12.4219V28.2422H4.39453C2.94067 28.2422 1.75781 27.0593 1.75781 25.6055V4.39453Z"
										fill="white"
									/>
								</svg>
							</a>

							<div className="center-link">
								<a
									href="https://de-de.facebook.com/Laura-Kie%C3%9Fkalt-Blockfl%C3%B6tistin-Mezzo-Sopran-545325259157089/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Laura Kießkalt
								</a>
							</div>
						</div>
					</div>

					<div className="contact-item">
						<div className="hover-box">
							<a href="https://www.instagram.com/laurakiesskalt/" target="_blank" rel="noopener noreferrer">
								<svg
									width="38"
									height="38"
									viewBox="0 0 38 38"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M26.9617 0H11.0384C4.9518 0 0 4.9518 0 11.0384V26.9616C0 33.0482 4.9518 38 11.0384 38H26.9616C33.0482 38 38 33.0482 38 26.9617V11.0384C38 4.9518 33.0482 0 26.9617 0ZM35.0312 26.9616C35.0312 31.4112 31.4112 35.0312 26.9617 35.0312H11.0384C6.58877 35.0312 2.96875 31.4112 2.96875 26.9617V11.0384C2.96875 6.58877 6.58877 2.96875 11.0384 2.96875H26.9616C31.4112 2.96875 35.0312 6.58877 35.0312 11.0384V26.9616Z"
										fill="white"
									/>
									<path
										d="M19 8.75781C13.3524 8.75781 8.75781 13.3524 8.75781 19C8.75781 24.6476 13.3524 29.2422 19 29.2422C24.6476 29.2422 29.2422 24.6476 29.2422 19C29.2422 13.3524 24.6476 8.75781 19 8.75781ZM19 26.2734C14.9894 26.2734 11.7266 23.0106 11.7266 19C11.7266 14.9894 14.9894 11.7266 19 11.7266C23.0106 11.7266 26.2734 14.9894 26.2734 19C26.2734 23.0106 23.0106 26.2734 19 26.2734Z"
										fill="white"
									/>
									<path
										d="M29.3906 10.0938C30.2104 10.0938 30.875 9.42917 30.875 8.60938C30.875 7.78958 30.2104 7.125 29.3906 7.125C28.5708 7.125 27.9062 7.78958 27.9062 8.60938C27.9062 9.42917 28.5708 10.0938 29.3906 10.0938Z"
										fill="white"
									/>
								</svg>
							</a>

							<div className="center-link">
								<a href="https://www.instagram.com/laurakiesskalt/" target="_blank" rel="noopener noreferrer">
								laurakiesskalt
								</a>
							</div>
						</div>
					</div>
					<div className="contact-item">
						<div className="hover-box">
							<a href="https://www.linkedin.com/in/laura-kießkalt-19296552/" target="_blank" rel="noopener noreferrer">
							<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M160.008 423H90.0078V197H160.008V423ZM166.992 124.996C166.992 102.367 148.633 84 126.016 84C103.312 84 85 102.367 85 124.996C85 147.633 103.312 166 126.016 166C148.633 166 166.992 147.633 166.992 124.996ZM422 298.664C422 237.996 409.184 193 338.312 193C304.258 193 281.398 210.031 272.066 227.742H272V197H204V423H272V310.789C272 281.402 279.48 252.934 315.906 252.934C351.836 252.934 353 286.539 353 312.656V423H422V298.664ZM512 452V60C512 26.9141 485.086 0 452 0H60C26.9141 0 0 26.9141 0 60V452C0 485.086 26.9141 512 60 512H452C485.086 512 512 485.086 512 452V452ZM452 40C463.027 40 472 48.9727 472 60V452C472 463.027 463.027 472 452 472H60C48.9727 472 40 463.027 40 452V60C40 48.9727 48.9727 40 60 40H452Z" fill="white"/>
</svg>

							</a>

							<div className="center-link">
								<a href="https://www.linkedin.com/in/laura-kießkalt-19296552/" target="_blank" rel="noopener noreferrer">
								laurakiesskalt
								</a>
							</div>
						</div>
					</div>
				
					{/*
					<div className="contact-item">
						<div className="agency-box">
							<a>
								<svg
									width="40"
									height="40"
									viewBox="0 0 40 40"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clip-path="url(#clip0)">
										<path
											d="M30.6695 5.02104H29.9585V4.31003C29.9586 1.93354 28.0252 0.000183105 25.6487 0.000183105H14.3518C11.9753 0.000183105 10.0419 1.93354 10.0419 4.31003V5.02104H9.33092C6.95443 5.02104 5.021 6.95448 5.021 9.33096V38.8283C5.021 39.4755 5.54568 40.0002 6.19287 40.0002H33.8075C34.4547 40.0002 34.9794 39.4755 34.9794 38.8283V9.33096C34.9794 6.95448 33.046 5.02104 30.6695 5.02104ZM12.3857 4.31003C12.3857 3.22589 13.2676 2.34393 14.3518 2.34393H25.6487C26.7329 2.34393 27.6148 3.22589 27.6148 4.31003V5.02104H12.3857V4.31003ZM21.8831 32.4689C22.9672 32.4689 23.8492 33.351 23.8492 34.4351V37.6564H21.1721V32.4689H21.8831ZM18.8283 37.6564H16.1512V34.4351C16.1512 33.351 17.0333 32.4689 18.1174 32.4689H18.8283V37.6564ZM32.6356 37.6564H26.1929V34.4351C26.1929 32.0586 24.2595 30.1252 21.883 30.1252H18.1174C15.7409 30.1252 13.8075 32.0586 13.8075 34.4351V37.6564H7.36482V9.33096C7.36482 8.24682 8.24686 7.36479 9.331 7.36479H30.6696C31.7537 7.36479 32.6357 8.24682 32.6357 9.33096V37.6564H32.6356Z"
											fill="white"
										/>
										<path
											d="M12.4688 10.0417C12.1602 10.0417 11.8586 10.1668 11.6399 10.3848C11.422 10.6035 11.2969 10.9059 11.2969 11.2136C11.2969 11.5222 11.422 11.8238 11.6399 12.0425C11.8587 12.2605 12.1602 12.3855 12.4688 12.3855C12.7773 12.3855 13.079 12.2605 13.2977 12.0425C13.5156 11.8245 13.6406 11.5222 13.6406 11.2136C13.6406 10.9059 13.5156 10.6034 13.2977 10.3848C13.0789 10.1668 12.7773 10.0417 12.4688 10.0417Z"
											fill="white"
										/>
										<path
											d="M17.4897 10.0417C17.1812 10.0417 16.8788 10.1668 16.6608 10.3848C16.4429 10.6035 16.3179 10.9059 16.3179 11.2136C16.3179 11.5222 16.4429 11.8238 16.6608 12.0425C16.8788 12.2605 17.1812 12.3855 17.4897 12.3855C17.7983 12.3855 18.0999 12.2605 18.3179 12.0425C18.5366 11.8237 18.6616 11.5222 18.6616 11.2136C18.6616 10.9059 18.5365 10.6034 18.3179 10.3848C18.0999 10.1668 17.7976 10.0417 17.4897 10.0417Z"
											fill="white"
										/>
										<path
											d="M12.4688 15.0629C12.1602 15.0629 11.8586 15.1879 11.6399 15.4059C11.422 15.6247 11.2969 15.9262 11.2969 16.2348C11.2969 16.5434 11.422 16.8457 11.6399 17.0637C11.8587 17.2816 12.1602 17.4066 12.4688 17.4066C12.7773 17.4066 13.079 17.2816 13.2977 17.0637C13.5156 16.8457 13.6406 16.5434 13.6406 16.2348C13.6406 15.9262 13.5156 15.6246 13.2977 15.4059C13.0789 15.1878 12.7773 15.0629 12.4688 15.0629Z"
											fill="white"
										/>
										<path
											d="M17.4897 15.0629C17.1812 15.0629 16.8788 15.1879 16.6608 15.4059C16.4429 15.6247 16.3179 15.9262 16.3179 16.2348C16.3179 16.5434 16.4429 16.8457 16.6608 17.0637C16.8788 17.2816 17.1812 17.4066 17.4897 17.4066C17.7975 17.4066 18.0999 17.2816 18.3186 17.0637C18.5365 16.8457 18.6616 16.5434 18.6616 16.2348C18.6616 15.9262 18.5365 15.6246 18.3186 15.4059C18.0999 15.1878 17.7976 15.0629 17.4897 15.0629Z"
											fill="white"
										/>
										<path
											d="M12.4688 20.0838C12.1602 20.0838 11.8586 20.2089 11.6399 20.4269C11.422 20.6449 11.2969 20.9472 11.2969 21.2557C11.2969 21.5635 11.422 21.866 11.6399 22.0839C11.8587 22.3027 12.1602 22.4276 12.4688 22.4276C12.7773 22.4276 13.079 22.3026 13.2977 22.0839C13.5156 21.866 13.6406 21.5636 13.6406 21.2557C13.6406 20.9472 13.5156 20.6449 13.2977 20.4269C13.0789 20.2089 12.7773 20.0838 12.4688 20.0838Z"
											fill="white"
										/>
										<path
											d="M17.4897 20.0838C17.1812 20.0838 16.8788 20.2089 16.6608 20.4269C16.4429 20.6449 16.3179 20.9472 16.3179 21.2557C16.3179 21.5635 16.4429 21.866 16.6608 22.0839C16.8788 22.3027 17.1812 22.4276 17.4897 22.4276C17.7975 22.4276 18.0999 22.3026 18.3186 22.0839C18.5365 21.866 18.6616 21.5636 18.6616 21.2557C18.6616 20.9472 18.5365 20.6449 18.3186 20.4269C18.0999 20.2089 17.7976 20.0838 17.4897 20.0838Z"
											fill="white"
										/>
										<path
											d="M12.4688 25.104C12.1602 25.104 11.8586 25.229 11.6399 25.4477C11.422 25.6656 11.2969 25.9672 11.2969 26.2759C11.2969 26.5844 11.422 26.8867 11.6399 27.1047C11.8587 27.3227 12.1602 27.4478 12.4688 27.4478C12.7773 27.4478 13.0797 27.3227 13.2977 27.1047C13.5156 26.8867 13.6406 26.5844 13.6406 26.2759C13.6406 25.9673 13.5156 25.6656 13.2977 25.4477C13.0797 25.229 12.7773 25.104 12.4688 25.104Z"
											fill="white"
										/>
										<path
											d="M17.4897 25.104C17.1812 25.104 16.8788 25.229 16.6608 25.4477C16.4429 25.6656 16.3179 25.9672 16.3179 26.2759C16.3179 26.5844 16.4429 26.8867 16.6608 27.1047C16.8788 27.3227 17.1812 27.4478 17.4897 27.4478C17.7983 27.4478 18.0999 27.3227 18.3179 27.1047C18.5366 26.8867 18.6616 26.5844 18.6616 26.2759C18.6616 25.9673 18.5365 25.6656 18.3179 25.4477C18.0999 25.229 17.7983 25.104 17.4897 25.104Z"
											fill="white"
										/>
										<path
											d="M22.51 10.0417C22.2022 10.0417 21.8998 10.1668 21.6819 10.3848C21.4639 10.6035 21.3389 10.9051 21.3389 11.2136C21.3389 11.5222 21.4639 11.8245 21.6819 12.0425C21.8998 12.2605 22.2014 12.3855 22.51 12.3855C22.8186 12.3855 23.121 12.2605 23.3389 12.0425C23.5569 11.8245 23.6819 11.5222 23.6819 11.2136C23.6819 10.9059 23.5569 10.6034 23.3389 10.3848C23.121 10.1668 22.8186 10.0417 22.51 10.0417Z"
											fill="white"
										/>
										<path
											d="M27.5312 10.0417C27.2227 10.0417 26.9211 10.1668 26.7024 10.3848C26.4845 10.6035 26.3594 10.9059 26.3594 11.2136C26.3594 11.5222 26.4845 11.8245 26.7024 12.0425C26.9204 12.2605 27.2227 12.3855 27.5312 12.3855C27.8398 12.3855 28.1415 12.2605 28.3602 12.0425C28.5781 11.8245 28.7031 11.5222 28.7031 11.2136C28.7031 10.9051 28.5781 10.6034 28.3602 10.3848C28.1415 10.1668 27.8399 10.0417 27.5312 10.0417Z"
											fill="white"
										/>
										<path
											d="M22.5103 15.0629C22.2024 15.0629 21.9 15.1879 21.6821 15.4059C21.4633 15.6247 21.3384 15.9262 21.3384 16.2348C21.3384 16.5434 21.4634 16.8457 21.6821 17.0637C21.9 17.2816 22.2024 17.4066 22.5103 17.4066C22.8188 17.4066 23.1204 17.2816 23.3392 17.0637C23.5571 16.8457 23.6821 16.5434 23.6821 16.2348C23.6821 15.9262 23.5571 15.6246 23.3392 15.4059C23.1204 15.1878 22.8188 15.0629 22.5103 15.0629Z"
											fill="white"
										/>
										<path
											d="M27.5312 15.0629C27.2227 15.0629 26.9211 15.1879 26.7024 15.4059C26.4845 15.6247 26.3594 15.9262 26.3594 16.2348C26.3594 16.5434 26.4845 16.8449 26.7024 17.0637C26.9212 17.2816 27.2227 17.4066 27.5312 17.4066C27.8398 17.4066 28.1415 17.2816 28.3602 17.0637C28.5781 16.8448 28.7031 16.5434 28.7031 16.2348C28.7031 15.9262 28.5781 15.6246 28.3602 15.4059C28.1415 15.1878 27.8399 15.0629 27.5312 15.0629Z"
											fill="white"
										/>
										<path
											d="M22.51 20.0838C22.2022 20.0838 21.8998 20.2089 21.6819 20.4269C21.4639 20.6449 21.3389 20.9472 21.3389 21.2557C21.3389 21.5643 21.4639 21.866 21.6819 22.0839C21.8998 22.3027 22.2021 22.4276 22.51 22.4276C22.8186 22.4276 23.121 22.3026 23.3389 22.0839C23.5569 21.866 23.6819 21.5636 23.6819 21.2557C23.6819 20.9472 23.5569 20.6449 23.3389 20.4269C23.121 20.2089 22.8186 20.0838 22.51 20.0838Z"
											fill="white"
										/>
										<path
											d="M27.5312 20.0838C27.2227 20.0838 26.9211 20.2089 26.7024 20.4269C26.4845 20.6449 26.3594 20.9472 26.3594 21.2557C26.3594 21.5635 26.4845 21.866 26.7024 22.0839C26.9212 22.3027 27.2227 22.4276 27.5312 22.4276C27.8398 22.4276 28.1415 22.3026 28.3602 22.0839C28.5781 21.866 28.7031 21.5636 28.7031 21.2557C28.7031 20.9472 28.5781 20.6449 28.3602 20.4269C28.1415 20.2089 27.8399 20.0838 27.5312 20.0838Z"
											fill="white"
										/>
										<path
											d="M22.5103 25.104C22.2024 25.104 21.9 25.229 21.6821 25.4477C21.4633 25.6656 21.3384 25.9672 21.3384 26.2759C21.3384 26.5844 21.4634 26.8867 21.6821 27.1047C21.9 27.3227 22.2024 27.4478 22.5103 27.4478C22.8188 27.4478 23.1212 27.3227 23.3392 27.1047C23.5571 26.8867 23.6821 26.5844 23.6821 26.2759C23.6821 25.9673 23.5571 25.6656 23.3392 25.4477C23.1212 25.229 22.8188 25.104 22.5103 25.104Z"
											fill="white"
										/>
										<path
											d="M27.5312 25.104C27.2227 25.104 26.9211 25.229 26.7024 25.4477C26.4845 25.6656 26.3594 25.9672 26.3594 26.2759C26.3594 26.5844 26.4845 26.8867 26.7024 27.1047C26.9212 27.3227 27.2227 27.4478 27.5312 27.4478C27.8398 27.4478 28.1415 27.3227 28.3602 27.1047C28.5781 26.8867 28.7031 26.5844 28.7031 26.2759C28.7031 25.9673 28.5781 25.6656 28.3602 25.4477C28.1415 25.229 27.8399 25.104 27.5312 25.104Z"
											fill="white"
										/>
									</g>
									<defs>
										<clipPath id="clip0">
											<rect
												width="40"
												height="40"
												fill="white"
												transform="translate(0 0.000183105)"
											/>
										</clipPath>
									</defs>
								</svg>
							</a>

							<p>
								<b>c /o Mag. Tamara Herzl - Künstlermanagement</b>
								<br />
								Breyerstrasse 3/ Top 3<br />
								A-2500 Baden<br />
								T: +43 676 9505244<br />
								E-Mail: <a href="mailto:contact@tamaraherzl.com">contact@tamaraherzl.com</a>
								<br />
							</p>
						</div>
					</div>
					*/}
				</div>
							</p>
						</div>
					</div>
				</section>
			</section>
	);
}

export default withNamespaces()(Contact);
