import React from 'react';
import moment from 'moment';
import Event from './Event';

function EventList({ events, language }) {
	const sortedEvents = events.sort((a, b) => moment(a.fields.begin).diff(b.fields.begin));
	return (
		<React.Fragment>
			{events.length !== 0 ? (
				sortedEvents.map((event, index) => {
					return <Event event={event} key={index} language={language} />;
				})
			) : (
				<div className="no-event-grid">
					<div className="no-events">no upcoming events</div>
				</div>
			)}
		</React.Fragment>
	);
}

export default EventList;
