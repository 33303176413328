import React from 'react';
import { withNamespaces } from 'react-i18next';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";


    function GalleryE3S () {
        const option = {
            settings: {
              autoplaySpeed: 3000,
              boxShadow: 'none',
              disableKeyboardControls: false,
              disablePanzoom: true,
              disableWheelControls: false,
              hideControlsAfter: 3000,
              lightboxTransitionSpeed: 0.3,
              lightboxTransitionTimingFunction: 'linear',
              overlayColor: 'rgba(35, 35, 35, 0.9)',
              slideAnimationType: 'fade',
              slideSpringValues: [300, 50],
              slideTransitionSpeed: 0.6,
              slideTransitionTimingFunction: 'linear',
              usingPreact: false
            }
          };
	return (
		<div>
		<div className="gallery-e-section">
			<h2 className="normal-headline">On Stage</h2>
			<SimpleReactLightbox>
			<SRLWrapper options={option}>
			<div className="gallery-e-grid">
				<div className="col-1 col">
				<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-12-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-12.jpg" alt=""/>
						</a>
					</div>
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-07-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-07.jpg" alt=""/>
						</a>
					</div>
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-03-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-03.jpg" alt=""/>
						</a>
					</div>
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-04-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-04.jpg" alt=""/>
						</a>
					</div>
					
				</div>
				<div className="col-2 col">
			
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-05-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-05.jpg" alt=""/>
						</a>
					</div>
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-06-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-06.jpg" alt=""/>
						</a>
					</div>
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-08-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-08.jpg" alt=""/>
						</a>
					</div>
				</div>
				<div className="col-3 col">
				<div className="item">
				<a href="img/gallery/onstage-f/onstage-f-09-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-09.jpg" alt=""/>
						</a>
					</div>
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-10-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-10.jpg" alt=""/>
						</a>
					</div>
					<div className="item">
					<a href="img/gallery/onstage-f/onstage-f-11-big.jpg">

						<img src="img/gallery/onstage-f/onstage-f-11.jpg" alt=""/>
						</a>
					</div>
				
				
					
				</div>
			</div>
			</ SRLWrapper>
			</ SimpleReactLightbox>

		</div>

		</div>
	);
}

export default GalleryE3S;
