
import React from 'react';
import { withNamespaces } from 'react-i18next';


function GalleryF({language ,t}) {
	return (
		<div>
		<div id="gallery"></div>
		<div className="gallery-section">
			<h2 className="normal-headline">
			{t('Galerie')}
			</h2>
			<div className="gallery-preview">
				<a href="/portrait">
				<div className="item">
					<img src="img/gallery/cover-portrait-f.jpg" alt=""/>
					<div className="overlay">
						<div className="text">Portrait</div>
					</div> 
				</div>
				</a>
				<a href="/onstage-f">
		 		<div className="item">
				<img src="img/gallery/cover-onstage-f.jpg" alt=""/>
					<div className="overlay">
						<div className="text">On Stage</div>
					</div>
				</div>
				</a>
				
			</div>
<div class="elfsight-app-bcc65f41-8777-4e66-b7b1-6ed7ac2c2c91"></div>

		</div>

		</div>
	);
}

export default withNamespaces()(GalleryF);
