
import React, { Component } from 'react';

export default class MediaF extends Component {

	render() { 

        
		return (
			<div>            {/* Youtube */}
			<div id="media"></div>
            <div className="youtube-section">
            	<h2 className="normal-headline" id="videos">Media</h2>
<div class="elfsight-app-24985d1b-0b54-4ca5-9ee2-b73c48827a97"></div><div className="soundcloud-section">     
<div className="row-1">
	<div className="item">
	<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/680903183&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
		<p className="fade-text-media" data-emergence="hidden">G.P. Telemann (1681 - 1767): Mich Tröstet Die Hoffnung (aus "Der Geduldige Sokrates")</p>
	</div>
	<div className="item">

	<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/680902697&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>

		<p className="fade-text-media" data-emergence="hidden"> J.S. Bach (1685 - 1750): Sonate G Dur (BWV 1021) - Adagio </p>
	</div>
	<div className="item">


	<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/680902334&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
		<p className="fade-text-media" data-emergence="hidden"> J.S. Bach (1685 - 1750): Sonate G Dur (BWV 1021) - Vivace </p>
	</div>

</div>
<div className="row-2">
<div className="item">
<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/680901932&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>

	<p className="fade-text-media" data-emergence="hidden"> T. Merula (1595 - 1665): Ciaccona </p>
</div>
<div className="item">

<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/367156202&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
	<p className="fade-text-media" data-emergence="hidden"> Jan van der Roost (*1956): I Continenti - Südamerika </p>
</div>


</div>
</div>
</div>
</div>
		);
	}
}
