import React from 'react';
import EventList from '../components/calendar/EventList';

function CalendarExtra({ events }) {
	return (
		<div>
			<div id="calendar" />
			<div className="calendar-section">
				<div className="item-calendar">
					<h2 className="normal-headline">Calendar</h2>
					<div className="calendar-grid">
						<EventList events={events} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CalendarExtra;
