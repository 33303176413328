import React, { useEffect } from 'react';
import BioContent from '../components/bio/BioContent';
//import { gsap, TimelineLite, Power3 } from 'gsap';
//import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { withNamespaces } from 'react-i18next';

//gsap.registerPlugin(ScrollTrigger);

function BiographyS({ biography, language, t }) {
	return (
		<div>
			<div id="bio" />
			<section className="animation-bi bio-s">
				<section className="animation-bio" id="animation-bio">
					<div className="trigger">
						<p className="textbio">
							<p className="inside-bio">
								{biography.slice(0, 1).map((bio, index) => {
									return <BioContent biography={bio} key={index} language={language} />;
								})}

								{/* <h2 className="normal-headline">Biografie</h2>
								<div className="bio-text">
									Laura Kießkalt studiert seit WS 2015 bei Regina Prasser MA an der Universität
									Mozarteum Salzburg Instrumentalpädagogik Gesang, erhält bei Thérèse Lindquist
									Unterricht im Liedfach sowie bei Andreas Scholl im Bereich Alte Musik, Barockgesang.
									<br />
									Als Mezzosopranistin gab sie ihr Solo-Debüt im Großen Saal der Stiftung Mozarteum in
									Salzburg und war neben Konzerten in Österreich und Deutschland u.a. mit dem Chor
									„Musicacosi“ unter dem Dirigat von Sir Elliot Gardiner beim Festival Anima Mundi im
									Dom von Pisa sowie in „La Clemenza di Tito“ unter Josef Wallnig im Teatro Communale
									Florenz und Teatro Communale Fiesole zu hören.
									<br />
									In dem „Chor der KlangVerwaltung“, ehemals von Enoch zu Guttenberg gegründet und nun
									unter der Leitung von Jörn Andresen sowie Christiane Büttig, singt Laura Kießkalt
									seit 2020 als Altistin in der Stammbesetzung. Der Chor setzt sich aus
									professionellen, erfahrenen, jungen Sängerinnen und Sängern zusammen, darunter
									Mitglieder des Philharmonischen Chors München, des Chors des Bayerischen Rundfunks,
									des Collegium Vocale Gent und anderer renommierter Vokalensembles.
									<br />
									<br />
									Seit 2018 ist die Mezzosopranistin Mitglied in dem Vokalensemble Das Vokalprojekt,
									dem Musikkritiker „fein durchgearbeitete Interpretationen“, “exzellente Intonation“
									und eine „hervorragend geschulte Stimmkultur“ bescheinigen. Im April entstand in
									Kooperation mit der bayerischen Kammerphilharmonie und dem Bayerischen Rundfunk eine
									CD von Leopold Mozarts „Missa solemnis in c“. Zu den Innsbrucker Festwochen der Alte
									Musik 2020 wurde Das Vokalprojekt von Alessandro de Marchi als Gastensemble
									einladen.
									<br />
									Laura Kießkalt ist zudem Mitglied des renommierten Salzburger Bachchores, der sich
									unter der Leitung von Alois Glaßner zu einem der führenden österreichischen
									Vokalensembles etabliert und auch internationales Ansehen erworben hat. Im Zuge
									dessen sang sie unter anderem bei den Salzburger Osterfestspielen unter Christian
									Thielemann „Die Meistersinger von Nürnberg" und bei den Salzburger Festspielen unter
									Sir Ivor Bolton und Andrew Manze.
									<br />
									Neben ihrer Leidenschaft für das schwedisches Lied, fühlt sich die junge Sängerin
									besonders dem Oratorienfach verbunden. Speziell für das Repertoire barocker
									Komponisten wie J.S. Bach bringt sie ein hohes Maß an aufführungspraktischem
									Verständnis aufgrund ihrer vorherigen Studien im Alte Musik Bereich mit.
									<br />
									Laura Kießkalt, geboren in Regensburg, schloss ihre Master und Bachelor Studien an
									der Universität Mozarteum Salzburg im Konzertfach Blockflöte sowie
									Instrumentalpädagogik Blockflöte bei Univ. Prof. Dorothee Oberlinger mit
									Auszeichnung ab. Zudem wurde sie mit zahlreichen Preisen und Stipendien für ihr
									künstlerisches Schaffen ausgezeichnet und ist bei bekannten Festivals wie „Tage
									Alter Musik Regensburg“ sowie in den Rundfunksendern BR Klassik und MDR zu hören
									gewesen.
								</div> */}
							</p>
						</p>
					</div>
				</section>
			</section>
		</div>
	);
}

export default withNamespaces()(BiographyS);
