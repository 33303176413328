import './styles/App.scss';
import NavBlack from './components/common/NavBlack';
import NavBlackS from './components/common/NavBlackS';
import NavBlackSOut from './components/common/NavBlackSOut';
import NavBlackOut from './components/common/NavBlackOut';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Biography from './pages/Biography';
import BiographyS from './pages/BiographyS';
import MediaS from './pages/MediaS';
import MediaF from './pages/MediaF';
import GalleryF from './pages/GalleryF';
import GalleryS from './pages/GalleryS';
import GalleryE1 from './pages/GalleryE1';
import GalleryE2 from './pages/GalleryE2';
import GalleryE2S from './pages/GalleryE2S';
import GalleryE3S from './pages/GalleryE3S';
import EnsemblesF from './pages/EnsemblesF';
import EnsemblesS from './pages/EnsemblesS';

import ReferencesF from './pages/ReferencesF';
import ReferencesS from './pages/ReferencesS';
import FooterBlack from './components/common/FooterBlack';
import Contact from './pages/Contact';
import ContactS from './pages/ContactS';
import Switch from './pages/Switch';
import PaedagogikF from './pages/PaedagogikF';
import PaedagogikS from './pages/PaedagogikS';
import Calendar from './pages/Calendar';
import CalendarExtra from './pages/CalendarExtra';
import Imprint from './pages/imprint';
import Privacy from './pages/privacy';
import { client } from './client';
import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from './i18n';
import CookieConsent, { Cookies } from 'react-cookie-consent';

function App() {
	const [ events, setEvents ] = useState([]);
	const [ biography, setBiography ] = useState([]);
	const [ biographySopran, setBiographySopran ] = useState([]);
	const [ paedaBlock, setPaedaBlock ] = useState([]);
	const [ pdSopran, setPdSopran ] = useState([]);
	const [ germanLanguage, toggleGermanLanguage ] = useState(true);


	useEffect(() => {

		client
			.getEntries()
			.then((response) => {
				let events = [];
				let biographySopran = [];
				let biographyBlockfloete = [];
				let paedagogikBlock = [];
				let paedagogikSopran = [];
				response.items.map((item, index) => {
					let actualItem = item.sys.contentType.sys.id;
					if (actualItem === 'date') {
						events.push(item);
					} else if (actualItem === 'biography') {
						biographyBlockfloete.push(item);
					} else if (actualItem === 'biografieSopran') {
						biographySopran.push(item);
					} else if (actualItem === 'pdagogik') {
						paedagogikBlock.push(item);
					} else if (actualItem === 'pdagogikSopran') {
						paedagogikSopran.push(item);
					}
					return null;
				});
				setEvents(events);
				setBiography(biographyBlockfloete);
				setBiographySopran(biographySopran);
				setPaedaBlock(paedagogikBlock);
				setPdSopran(paedagogikSopran);
			})
			.catch(console.error);
	}, []);

	return (
		<div className="App">
			<Router>
	
			<Route exact path="/vita/laura-kießkalt/">
			<Redirect exact to="/floetistin" />
			</Route>
				<Route exact path="/">
					<Switch />
					<CookieConsent
						location="bottom"
						buttonText="Accept"
						cookieName="cookieSao"
						style={{ background: '#000001', marginLeft: '0px' }}
						buttonStyle={{
							color: '#000000',
							fontSize: '13px',
							background: '#FFFFFF',
							padding: '7px 45px',
							margin: '20px 25px'
						}}
						expires={150}
					>
						<p style={{ marginBottom: '-10px', marginLeft: '10px', fontSize: '13px' }}>
							We and some of our partners use cookies and similar technologies for the purposes described
							in the Cookie Policy.<br /> Cookies and similar technologies.<br />
							You consent to the use of such technologies by closing this notice.
						</p>
						<br />{' '}
						<span style={{ fontSize: '13px', color: '#000000', marginLeft: '10px' }}>
							<a href="/privacy" style={{ fontSize: '13x', color: '#FFFFFF' }}>
								Mehr erfahren
							</a>
						</span>
					</CookieConsent>
					<FooterBlack />
				</Route>
				
				<Route exact path="/floetistin">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<Biography biography={biography} language={germanLanguage}/>
					<ReferencesF language={germanLanguage} />
					<MediaF language={germanLanguage}/>
					<Calendar events={events} language={germanLanguage} />
					<PaedagogikF paedagogik={paedaBlock} language={germanLanguage} />
					<EnsemblesF language={germanLanguage}/>
					<GalleryF language={germanLanguage}/>
					<Contact language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>
				<Route exact path="/sing">
					<NavBlackS language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<BiographyS biography={biographySopran} language={germanLanguage}/>
					<ReferencesS language={germanLanguage}/>

					<MediaS language={germanLanguage}/>

					<Calendar events={events} language={germanLanguage}/>
					<PaedagogikS paedagogik={pdSopran} language={germanLanguage}/>
					{/* 
					<EnsemblesS language={germanLanguage}/>
					*/}

					<GalleryS language={germanLanguage}/>
					<ContactS language={germanLanguage}/>

					<FooterBlack language={germanLanguage}/>
				</Route>

				<Route exact path="/calendar">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<CalendarExtra events={events} language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>

				<Route exact path="/onstage-f">
					<NavBlackOut language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<GalleryE1 language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>
				<Route exact path="/onstage-s">
					<NavBlackSOut language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<GalleryE3S language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>
				<Route exact path="/portrait">
					<NavBlackOut language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<GalleryE2 language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>
				<Route exact path="/portrait-s">
					<NavBlackSOut language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<GalleryE2S language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>

				<Route exact path="/imprint">
					<NavBlackSOut language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<Imprint language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>

				<Route exact path="/privacy">
					<NavBlackSOut language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)}/>
					<Privacy language={germanLanguage}/>
					<FooterBlack language={germanLanguage}/>
				</Route>
			</Router>
		</div>
	);
}

export default withNamespaces()(App);
