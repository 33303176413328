import React from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

function NavBlackOut({ t, language, onToggle }) {
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	const toggleGerman = () => {
		changeLanguage('de');
		onToggle(true);
	};

	const toggleEnglish = () => {
		changeLanguage('en');
		onToggle(false);
	};

	return (
		<div className="navbar navbar-black">
			<div className="nav-content">
				<div className="logo-mobile">
					<a href="/">
						<h1>Laura Kießkalt</h1>
					</a>
					<div className="site-switcher">
						<a className="active-site site-switch" href="/floetistin">
						{t('Blockflötistin')}
						</a>
						<a href="/sing" className="site-switch">
						{t('Mezzosopran')}

						</a>
					</div>
				</div>
				<ul className="nav-links">
					<li className="normal-link">
						<a href="/floetistin">{t('Biografie')}</a>
					</li>
					<li className="normal-link">
						<a href="/floetistin">Media</a>
					</li>

					<li className="normal-link">
						<a href="/floetistin">{t('Kalender')}</a>
					</li>
					<li className="logo-desktop">
						<a href="/">
							<h1>Laura Kießkalt</h1>
						</a>
						<div className="site-switcher">
							<a className="active-site site-switch" href="/floetistin">
							{t('Blockflötistin')}
							</a>
							<a href="/sing" className="site-switch">
							{t('Mezzosopran')}

							</a>
						</div>
					</li>

					<li className="normal-link">
						<a href="/floetistin">{t('Pädagogik')}</a>
					</li>
					<li className="normal-link">
						<a href="/floetistin">{t('Galerie')}</a>
					</li>
					<li className="normal-link">
						<a href="/floetistin">{t('Kontakt')}</a>
					</li>
					<div class="language-switch language-switch-mobile">
						<a className={language ? 'active-language' : ''} onClick={toggleGerman}>DE</a>
						<a class={language ? '' : 'active-language'} onClick={toggleEnglish} >EN</a>
					</div>
				</ul>
				<div className="burger">
					<div className="line line-1" />
					<div className="line line-2" />
					<div className="line line-3" />
				</div>
				<div class="language-switch language-switch-desktop">
					<a className={language ? 'active-language' : ''} onClick={toggleGerman}>
						DE
					</a>
					<a class={language ? '' : 'active-language'} onClick={toggleEnglish}>
						EN
					</a>
				</div>
			</div>
		</div>
	);
}
export default withNamespaces()(NavBlackOut);
