import React from 'react';
import { withNamespaces } from 'react-i18next';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";


    function GalleryE2() {
        const option = {
            settings: {
              autoplaySpeed: 3000,
              boxShadow: 'none',
              disableKeyboardControls: false,
              disablePanzoom: true,
              disableWheelControls: false,
              hideControlsAfter: 3000,
              lightboxTransitionSpeed: 0.3,
              lightboxTransitionTimingFunction: 'linear',
              overlayColor: 'rgba(35, 35, 35, 0.9)',
              slideAnimationType: 'fade',
              slideSpringValues: [300, 50],
              slideTransitionSpeed: 0.6,
              slideTransitionTimingFunction: 'linear',
              usingPreact: false
            }
          };
	return (
		<div>
		<div className="gallery-e-section">
			<h2 className="normal-headline">Portrait</h2>
			<SimpleReactLightbox>
			<SRLWrapper options={option}>
			<div className="gallery-e-grid">
			
				<div className="col-1 col">
					<div className="item">
						<a href="img/gallery/portrait-f/new-block-2022/big/gallery-new-off-stage-blockf-01-big.jpg">
						<img src="img/gallery/portrait-f/new-block-2022/small/gallery-new-off-stage-blockf-01-small.jpg" alt="Foto von Niko Zuparic"/>
						</a>
					</div>
					<div className="item">
						<a href="img/gallery/portrait-f/new-block-2022/big/gallery-new-off-stage-blockf-02-big.jpg">
						<img src="img/gallery/portrait-f/new-block-2022/small/gallery-new-off-stage-blockf-02-small.jpg" alt="Foto von Niko Zuparic"/>
						</a>
					</div>
					
					<div className="item">
					<a href="img/gallery/portrait-f/portrait-f-big-03.jpg">

						<img src="img/gallery/portrait-f/portrait-f-03.jpg" alt="Foto von Shirley Suarez"/>
					</a>
					</div>
					<div className="item">
					<a href="img/gallery/portrait-f/portrait-f-big-02.jpg">

						<img src="img/gallery/portrait-f/portrait-f-02.jpg" alt="Foto von Shirley Suarez"/>
					</a>
					</div>
					
				</div>
				<div className="col-2 col">
				<div className="item">
						<a href="img/gallery/portrait-f/new-block-2022/big/gallery-new-off-stage-blockf-03-big.jpg">
						<img src="img/gallery/portrait-f/new-block-2022/small/gallery-new-off-stage-blockf-03-small.jpg" alt="Foto von Niko Zuparic"/>
						</a>
					</div>
					<div className="item">
						<a href="img/gallery/portrait-f/new-block-2022/big/gallery-new-off-stage-blockf-04-big.jpg">
						<img src="img/gallery/portrait-f/new-block-2022/small/gallery-new-off-stage-blockf-04-small.jpg" alt="Foto von Niko Zuparic"/>
						</a>
					</div>
				<div className="item">
				<a href="img/gallery/portrait-f/portrait-f-big-04.jpg">

						<img src="img/gallery/portrait-f/portrait-f-04.jpg" alt="Foto von Shirley Suarez"/>
				</a>
					</div>
					<div className="item">
					<a href="img/gallery/portrait-f/portrait-f-big-05.jpg">

						<img src="img/gallery/portrait-f/portrait-f-05.jpg" alt="Foto von Shirley Suarez"/>
					</a>
					</div>
					<div className="item">
					<a href="img/gallery/portrait-f/portrait-f-big-06.jpg">

						<img src="img/gallery/portrait-f/portrait-f-06.jpg" alt="Foto von Shirley Suarez"/>
					</a>
					</div>
					<div className="item">
					<a href="img/gallery/portrait-f/portrait-f-10-big.jpg">

						<img src="img/gallery/portrait-f/portrait-f-10.jpg" alt="Foto von Shirley Suarez"/>
					</a>
					</div>
				
				</div>
				<div className="col-3 col">
				<div className="item">
						<a href="img/gallery/portrait-f/new-block-2022/big/gallery-new-off-stage-blockf-05-big.jpg">
						<img src="img/gallery/portrait-f/new-block-2022/small/gallery-new-off-stage-blockf-05-small.jpg" alt="Foto von Niko Zuparic"/>
						</a>
					</div>
					<div className="item">
						<a href="img/gallery/portrait-f/new-block-2022/big/gallery-new-off-stage-blockf-06-big.jpg">
						<img src="img/gallery/portrait-f/new-block-2022/small/gallery-new-off-stage-blockf-06-small.jpg" alt="Foto von Niko Zuparic"/>
						</a>
					</div>
					<div className="item">
						<a href="img/gallery/portrait-f/new-block-2022/big/gallery-new-off-stage-blockf-07-big.jpg">
						<img src="img/gallery/portrait-f/new-block-2022/small/gallery-new-off-stage-blockf-07-small.jpg" alt="Foto von Niko Zuparic"/>
						</a>
					</div>
				<div className="item">
				<a href="img/gallery/portrait-f/portrait-f-big-07.jpg">

						<img src="img/gallery/portrait-f/portrait-f-07.jpg" alt="Foto von Shirley Suarez"/>
				</a>
					</div>
					<div className="item">
					<a href="img/gallery/portrait-f/portrait-f-big-08.jpg">

						<img src="img/gallery/portrait-f/portrait-f-08.jpg" alt="Foto von Shirley Suarez"/>
					</a>
					</div>
					<div className="item">
					<a href="img/gallery/portrait-f/portrait-f-big-09.jpg">

						<img src="img/gallery/portrait-f/portrait-f-09.jpg" alt="Foto von Shirley Suarez"/>
					</a>
					</div>
					
					
				</div>
				
			</div>
			</ SRLWrapper >

				</ SimpleReactLightbox>
		</div>

		</div>
	);
}

export default GalleryE2;
