
import React from 'react';

function EnsemblesF( ) {
	return (
		<div className="ensembles-section">
			<h2 className="normal-headline">
				Ensembles
			</h2>
			<div className="ensembles-grid">
				
			<div className="item">

				<div className="sub-item">
					<img src="img/Ensemble-Beryllium-2.jpg" alt=""/>
					<div className="overlay">
						<div className="text">Ensemble Beryllium</div>
					</div> 
					</div>
				</div>
				<div className="item">
				<div className="sub-item">
					<img src="img/Ensemble-Molto-Vivo-2.jpg" alt=""/>
					<div className="overlay">
						<div className="text">Ensemble Molto Vivo</div>
					</div> 
					</div>
				</div>
			</div>
		</div>
	);
}

export default EnsemblesF;
