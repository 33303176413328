import React from 'react';
import PDFloete from '../components/PDFloete';
import { withNamespaces } from 'react-i18next';


function PeadagogikS({ paedagogik, language ,t }) {
	return (
		<div>
			<div id="paedagogik" />
			<div className="paedagogik-section">
				<div className="container-item fade-in" data-emergence="hidden">
					<h2 className="normal-headline">{t('Pädagogik')}</h2>
					{paedagogik.map((pd) => {
						return <PDFloete pd={pd} language={language}/>;
					})}
					{/* <p>
						Mir ist es ein großes Anliegen, BlockflötistInnen jeden Alters und Levels die Freude an ihrem
						Instrument, das in der heutigen Gesellschaft fälschlicherweise immer noch das Image des
						„Kinderinstrumentes“ trägt, zu vermitteln und zu erhalten. Ich möchte meine Schüler nicht nur
						grundlegende Aspekte des Flötenspiels lehren, wie Atmung, Haltung, Finger- und Zungentechnik,
						sondern Ihnen neben musikgeschichtlichen und musiktheoretischen Grundlagen auch einen breiten
						Überblick über das Repertoire der Blockflöte vom Mittelalter bis zur Moderne bieten.
						Ausgangspunkt ist jedoch die Vermittlung und Erhaltung der Freude an der Musik und am
						Musizieren.
						<br />
						Ich greife auf langjährige Unterrichtserfahrung, u.a. an einer staatlichen Musikschule und auf
						die mit Auszeichnung abgeschlossenen Studien der Instrumentalpädagogik Blockflöte und Gesang
						sowie im Konzertfach Blockflöte zurück.
						<br />
						Ebenfalls mit Leidenschaft gebe ich daher mein jahrelang erprobtes und stets erweitertes Wissen
						bezüglich Gesang an SchülerInnen jeden Ausbildungsstandes weiter.
					</p> */}
					<a href="mailto:laurakiesskalt.music@gmail.com" className="normal-btn">
					{t('contactp')}

					</a>
				</div>
			</div>
		</div>
	);
}

export default withNamespaces()(PeadagogikS);

