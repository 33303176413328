import React from 'react';

function Privacy() {
    return(
        <div className="imprint-content">       <h1>Datenschutzerkl&auml;rung</h1>
		<p > 
		</p>
		<p align="justify" >
		<br/>
		<br/>
		
		</p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Die
		Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
		Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
		vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
		sowie dieser Datenschutzerkl&auml;rung.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Die
		Nutzung unserer Webseite ist in der Regel ohne Angabe
		personenbezogener Daten m&ouml;glich. Soweit auf unseren Seiten
		personenbezogene Daten (beispielsweise Name, Anschrift oder
		E-Mail-Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich,
		stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
		ausdr&uuml;ckliche Zustimmung nicht an Dritte weitergegeben.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Wir
		weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B.
		bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen
		kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch
		Dritte ist nicht m&ouml;glich.</font></font></p>
		<h1 class="western"><a name="_vxz7g6rp6s2s"></a>
		<font size="5">1.   Allgemeines zur
		Datenverarbeitung</font></h1>
		<h3 class="western"><a name="_pw1y8lyzd9q"></a>
		 
		</h3>
		<p>
		<font color="#2d333d"><font size="3" >1.
		Umfang der Verarbeitung personenbezogener Daten</font></font></p>
		<p >
		<font color="#2d333d"><font size="3">Wir
		verarbeiten personenbezogene Daten unserer Nutzer grunds&auml;tzlich
		nur, soweit dies zur Bereitstellung einer funktionsf&auml;higen
		Webseite sowie unserer Inhalte und Leistungen erforderlich ist. Die
		Verarbeitung personenbezogener Daten unserer Nutzer erfolgt
		regelm&auml;&szlig;ig nur nach Einwilligung des Nutzers. Eine
		Ausnahme gilt in solchen F&auml;llen, in denen eine vorherige
		Einholung einer Einwilligung aus tats&auml;chlichen Gr&uuml;nden
		nicht m&ouml;glich ist und die Verarbeitung der Daten durch
		gesetzliche Vorschriften erforderlich ist.</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >2.
		Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >Soweit
		wir f&uuml;r Verarbeitungsvorg&auml;nge personenbezogener Daten eine
		Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 S.
		1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >Bei der
		Verarbeitung von personenbezogenen Daten, die zur Erf&uuml;llung
		eines Vertrages, dessen Vertragspartei die betroffene Person ist,
		erforderlich ist, dient Art. 6 Abs. 1 S. 1 lit. b DSGVO als
		Rechtsgrundlage. Dies gilt auch f&uuml;r Verarbeitungsvorg&auml;nge,
		die zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
		erforderlich sind.</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >Soweit
		eine Verarbeitung personenbezogener Daten zur Erf&uuml;llung einer
		rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
		unterliegt, dient Art. 6 Abs. 1 S. 1 lit. c DSGVO als
		Rechtsgrundlage.</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >F&uuml;r
		den Fall, dass lebenswichtige Interessen der betroffenen Person oder
		einer anderen nat&uuml;rlichen Person eine Verarbeitung
		personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 S. 1
		lit. d DSGVO als Rechtsgrundlage.</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >Ist die
		Verarbeitung zur Wahrung eines berechtigten Interesses unseres
		Unternehmens oder eines Dritten erforderlich und &uuml;berwiegen die
		Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
		erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 S. 1 lit. f
		DSGVO als Rechtsgrundlage f&uuml;r die Verarbeitung.</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >3.
		Datenl&ouml;schung und Speicherdauer</font></font></p>
		<p>
		<font color="#2d333d"><font size="3" >Die
		personenbezogenen Daten der betroffenen Person werden gel&ouml;scht
		oder gesperrt, sobald der Zweck der Speicherung entf&auml;llt. Eine
		Speicherung kann dar&uuml;ber hinaus erfolgen, wenn dies durch den
		europ&auml;ischen oder nationalen Gesetzgeber in unionsrechtlichen
		Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
		Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
		L&ouml;schung der Daten erfolgt auch dann, wenn eine durch die
		genannten Normen vorgeschriebene Speicherfrist abl&auml;uft, es sei
		denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten
		f&uuml;r einen Vertragsabschluss oder eine Vertragserf&uuml;llung
		besteht.</font></font></p>
		<h1 class="western"><a name="_i5x8qfrtog0q"></a>
		<font size="5">2. Rechte der betroffenen
		Person</font></h1>
		<p >
		<font color="#2d333d"><font size="3" >Werden
		personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener
		i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegen&uuml;ber dem
		Verantwortlichen zu:</font></font></p>
		<ol>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Auskunftsrecht<br/>
		Sie
			k&ouml;nnen von dem Verantwortlichen eine Best&auml;tigung dar&uuml;ber
			verlangen, ob personenbezogene Daten, die Sie betreffen, von ihm
			verarbeitet werden.<br/>
		Liegt eine solche Verarbeitung vor, k&ouml;nnen
			Sie von dem Verantwortlichen &uuml;ber folgende Informationen
			Auskunft verlangen: Ihnen steht das Recht zu, Auskunft dar&uuml;ber
			zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein
			Drittland oder an eine internationale Organisation &uuml;bermittelt
			werden. In diesem Zusammenhang k&ouml;nnen Sie verlangen, &uuml;ber
			die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
			&Uuml;bermittlung unterrichtet zu werden.<br/>
		<br/>
		Dieses
			Auskunftsrecht kann insoweit beschr&auml;nkt werden, als es
			voraussichtlich die Verwirklichung der Forschungs- oder
			Statistikzwecke unm&ouml;glich macht oder ernsthaft beeintr&auml;chtigt
			und die Beschr&auml;nkung f&uuml;r die Erf&uuml;llung der
			Forschungs- oder Statistikzwecke notwendig ist.</font></font></p>
			<ol>
				<li/>
		<p ><font color="#2d333d"><font size="3" >die
				Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >die
				Kategorien von personenbezogenen Daten, welche verarbeitet werden;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >die
				Empf&auml;nger bzw. die Kategorien von Empf&auml;ngern, gegen&uuml;ber
				denen die Sie betreffenden personenbezogenen Daten offengelegt
				wurden oder noch offengelegt werden;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >die
				geplante Dauer der Speicherung der Sie betreffenden
				personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
				m&ouml;glich sind, Kriterien f&uuml;r die Festlegung der
				Speicherdauer;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >das
				Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der Sie
				betreffenden personenbezogenen Daten, eines Rechts auf
				Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen oder
				eines Widerspruchsrechts gegen diese Verarbeitung;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >das
				Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >alle
				verf&uuml;gbaren Informationen &uuml;ber die Herkunft der Daten,
				wenn die personenbezogenen Daten nicht bei der betroffenen Person
				erhoben werden;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >das
				Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich
				Profiling gem&auml;&szlig; Art. 22 Abs. 1 und 4 DSGVO und zumindest
				in diesen F&auml;llen - aussagekr&auml;ftige Informationen &uuml;ber
				die involvierte Logik sowie die Tragweite und die angestrebten
				Auswirkungen einer derartigen Verarbeitung f&uuml;r die betroffene
				Person.</font></font></p>
			</ol>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Recht
			auf Berichtigung<br/>
		Sie haben ein Recht auf Berichtigung und/oder
			Vervollst&auml;ndigung gegen&uuml;ber dem Verantwortlichen, sofern
			die verarbeiteten personenbezogenen Daten, die Sie betreffen,
			unrichtig oder unvollst&auml;ndig sind. Der Verantwortliche hat die
			Berichtigung unverz&uuml;glich vorzunehmen.<br/>
		<br/>
		Ihr Recht auf
			Berichtigung kann insoweit beschr&auml;nkt werden, als es
			voraussichtlich die Verwirklichung der Forschungs- oder
			Statistikzwecke unm&ouml;glich macht oder ernsthaft beeintr&auml;chtigt
			und die Beschr&auml;nkung f&uuml;r die Erf&uuml;llung der
			Forschungs- oder Statistikzwecke notwendig ist.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Recht
			auf Einschr&auml;nkung der Verarbeitung<br/>
		Unter den folgenden
			Voraussetzungen k&ouml;nnen Sie die Einschr&auml;nkung der
			Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</font></font></p>
			<ol>
				<li/>
		<p ><font color="#2d333d"><font size="3" >wenn
				Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten
				f&uuml;r eine Dauer bestreiten, die es dem Verantwortlichen
				erm&ouml;glicht, die Richtigkeit der personenbezogenen Daten zu
				&uuml;berpr&uuml;fen;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >die
				Verarbeitung unrechtm&auml;&szlig;ig ist und Sie die L&ouml;schung
				der personenbezogenen Daten ablehnen und stattdessen die
				Einschr&auml;nkung der Nutzung der personenbezogenen Daten
				verlangen;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >der
				Verantwortliche die personenbezogenen Daten f&uuml;r die Zwecke der
				Verarbeitung nicht l&auml;nger ben&ouml;tigt, Sie diese jedoch zur
				Geltendmachung, Aus&uuml;bung oder Verteidigung von
				Rechtsanspr&uuml;chen ben&ouml;tigen, oder</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >wenn
				Sie Widerspruch gegen die Verarbeitung gem&auml;&szlig; Art. 21
				Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die
				berechtigten Gr&uuml;nde des Verantwortlichen gegen&uuml;ber Ihren
				Gr&uuml;nden &uuml;berwiegen.</font></font></p>
			</ol>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Wurde
			die Verarbeitung der Sie betreffenden personenbezogenen Daten
			eingeschr&auml;nkt, d&uuml;rfen diese Daten - von ihrer Speicherung
			abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung,
			Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum
			Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen
			Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen
			Interesses der Union oder eines Mitgliedstaats verarbeitet
			werden.<br/>
		Wurde die Einschr&auml;nkung der Verarbeitung nach den
			o.g. Voraussetzungen eingeschr&auml;nkt, werden Sie von dem
			Verantwortlichen unterrichtet bevor die Einschr&auml;nkung
			aufgehoben wird.<br/>
		<br/>
		Ihr Recht auf Einschr&auml;nkung der
			Verarbeitung kann insoweit beschr&auml;nkt werden, als es
			voraussichtlich die Verwirklichung der Forschungs- oder
			Statistikzwecke unm&ouml;glich macht oder ernsthaft beeintr&auml;chtigt
			und die Beschr&auml;nkung f&uuml;r die Erf&uuml;llung der
			Forschungs- oder Statistikzwecke notwendig ist.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Recht
			auf L&ouml;schung<br/>
		<br/>
		a) L&ouml;schungspflicht<br/>
		<br/>
		Sie
			k&ouml;nnen von dem Verantwortlichen verlangen, dass die Sie
			betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht
			werden, und der Verantwortliche ist verpflichtet, diese Daten
			unverz&uuml;glich zu l&ouml;schen, sofern einer der folgenden Gr&uuml;nde
			zutrifft:</font></font></p>
			<ol>
				<li/>
		<p ><font color="#2d333d"><font size="3" >Die
				Sie betreffenden personenbezogenen Daten sind f&uuml;r die Zwecke,
				f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet
				wurden, nicht mehr notwendig.</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >Sie
				widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
				Art. 6 Abs. 1 S. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO st&uuml;tzte,
				und es fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r die
				Verarbeitung.</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >Sie
				legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung
				ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r
				die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
				Widerspruch gegen die Verarbeitung ein.</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >Die
				Sie betreffenden personenbezogenen Daten wurden unrechtm&auml;&szlig;ig
				verarbeitet.</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >Die
				L&ouml;schung der Sie betreffenden personenbezogenen Daten ist zur
				Erf&uuml;llung einer rechtlichen Verpflichtung nach dem Unionsrecht
				oder dem Recht der Mitgliedstaaten erforderlich, dem der
				Verantwortliche unterliegt.</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >Die
				Sie betreffenden personenbezogenen Daten wurden in Bezug auf
				angebotene Dienste der Informationsgesellschaft gem&auml;&szlig;
				Art. 8 Abs. 1 DSGVO erhoben.</font></font></p>
			</ol>
			<li/>
		<p ><font color="#2d333d"><font size="3" >b)
			Informationen an Dritte<br/>
		Hat der Verantwortliche die Sie
			betreffenden personenbezogenen Daten &ouml;ffentlich gemacht und ist
			er gem. Art. 17 Abs. 1 DSGVO zu deren L&ouml;schung verpflichtet, so
			trifft er unter Ber&uuml;cksichtigung der verf&uuml;gbaren
			Technologie und der Implementierungskosten angemessene Ma&szlig;nahmen,
			auch technischer Art, um f&uuml;r die Datenverarbeitung
			Verantwortliche, die die personenbezogenen Daten verarbeiten,
			dar&uuml;ber zu informieren, dass Sie als betroffene Person von
			ihnen die L&ouml;schung aller Links zu diesen personenbezogenen
			Daten oder von Kopien oder Replikationen dieser personenbezogenen
			Daten verlangt haben.<br/>
		c) Ausnahmen<br/>
		Das Recht auf L&ouml;schung
			besteht nicht, soweit die Verarbeitung erforderlich ist</font></font></p>
			<ol>
				<li/>
		<p ><font color="#2d333d"><font size="3" >zur
				Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und
				Information;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >zur
				Erf&uuml;llung einer rechtlichen Verpflichtung, die die
				Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem
				der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung
				einer Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in
				Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem
				Verantwortlichen &uuml;bertragen wurde;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >aus
				Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich der
				&ouml;ffentlichen Gesundheit gem&auml;&szlig; Art. 9 Abs. 2 lit. h
				und i sowie Art. 9 Abs. 3 DSGVO;</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >f&uuml;r
				im &ouml;ffentlichen Interesse liegende Archivzwecke,
				wissenschaftliche oder historische Forschungszwecke oder f&uuml;r
				statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
				Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
				Ziele dieser Verarbeitung unm&ouml;glich macht oder ernsthaft
				beeintr&auml;chtigt, oder</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >zur
				Geltendmachung, Aus&uuml;bung oder Verteidigung von
				Rechtsanspr&uuml;chen.<br/>
		 </font></font>
				</p>
			</ol>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Recht
			auf Unterrichtung<br/>
		Haben Sie das Recht auf Berichtigung,
			L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber
			dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
			Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten
			offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten
			oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn,
			dies erweist sich als unm&ouml;glich oder ist mit einem
			unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden.<br/>
		<br/>
		Ihnen
			steht gegen&uuml;ber dem Verantwortlichen das Recht zu, &uuml;ber
			diese Empf&auml;nger unterrichtet zu werden.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Recht
			auf Daten&uuml;bertragbarkeit<br/>
		Sie haben das Recht, die Sie
			betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen
			bereitgestellt haben, in einem strukturierten, g&auml;ngigen und
			maschinen-lesbaren Format zu erhalten. Au&szlig;erdem haben Sie das
			Recht diese Daten einem anderen Verantwortlichen ohne Behinderung
			durch den Verantwortlichen, dem die personenbezogenen Daten
			bereitgestellt wurden, zu &uuml;bermitteln, sofern</font></font></p>
			<ol>
				<li/>
		<p ><font color="#2d333d"><font size="3" >die
				Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a
				DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem.
				Art. 6 Abs. 1 S. 1 lit. b DSGVO beruht und</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >die
				Verarbeitung mithilfe automatisierter Verfahren erfolgt.</font></font></p>
			</ol>
			<li/>
		<p ><font color="#2d333d"><font size="3" >In
			Aus&uuml;bung dieses Rechts haben Sie ferner das Recht, zu erwirken,
			dass die Sie betreffenden personenbezogenen Daten direkt von einem
			Verantwortlichen einem anderen Verantwortlichen &uuml;bermittelt
			werden, soweit dies technisch machbar ist. Freiheiten und Rechte
			anderer Personen d&uuml;rfen hierdurch nicht beeintr&auml;chtigt
			werden.<br/>
		Das Recht auf Daten&uuml;bertragbarkeit gilt nicht f&uuml;r
			eine Verarbeitung personenbezogener Daten, die f&uuml;r die
			Wahrnehmung einer Aufgabe erforderlich ist, die im &ouml;ffentlichen
			Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt
			erfolgt, die dem Verantwortlichen &uuml;bertragen wurde.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Widerspruchsrecht<br/>
		Sie
			haben das Recht, aus Gr&uuml;nden, die sich aus ihrer besonderen
			Situation ergeben, jederzeit gegen die Verarbeitung der Sie
			betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
			S. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
			auch f&uuml;r ein auf diese Bestimmungen gest&uuml;tztes
			Profiling.<br/>
		<br/>
		Der Verantwortliche verarbeitet die Sie
			betreffenden personenbezogenen Daten nicht mehr, es sei denn, er
			kann zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die
			Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
			&uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung,
			Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.<br/>
		<br/>
		Werden
			die Sie betreffenden personenbezogenen Daten verarbeitet, um
			Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
			Widerspruch gegen die Verarbeitung der Sie betreffenden
			personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
			dies gilt auch f&uuml;r das Profiling, soweit es mit solcher
			Direktwerbung in Verbindung steht.<br/>
		<br/>
		Widersprechen Sie der
			Verarbeitung f&uuml;r Zwecke der Direktwerbung, so werden die Sie
			betreffenden personenbezogenen Daten nicht mehr f&uuml;r diese
			Zwecke verarbeitet.<br/>
		<br/>
		Sie haben die M&ouml;glichkeit, im
			Zusammenhang mit der Nutzung von Diensten der
			Informationsgesellschaft - ungeachtet der Richtlinie 2002/58/EG -
			Ihr Widerspruchsrecht mittels automatisierter Verfahren auszu&uuml;ben,
			bei denen technische Spezifikationen verwendet werden.<br/>
		<br/>
		Sie
			haben auch das Recht, aus Gr&uuml;nden, die sich aus Ihrer
			besonderen Situation ergeben, bei der Verarbeitung Sie betreffender
			personenbezogener Daten, die zu wissenschaftlichen oder historischen
			Forschungszwecken oder zu statistischen Zwecken gem. Art. 89 Abs. 1
			DSGVO erfolgt, dieser zu widersprechen.<br/>
		<br/>
		Ihr
			Widerspruchsrecht kann insoweit beschr&auml;nkt werden, als es
			voraussichtlich die Verwirklichung der Forschungs- oder
			Statistikzwecke unm&ouml;glich macht oder ernsthaft beeintr&auml;chtigt
			und die Beschr&auml;nkung f&uuml;r die Erf&uuml;llung der
			Forschungs- oder Statistikzwecke notwendig ist.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Recht
			auf Widerruf der datenschutzrechtlichen Einwilligungserkl&auml;rung<br/>
		Sie
			haben das Recht, Ihre datenschutzrechtliche Einwilligungserkl&auml;rung
			jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird
			die Rechtm&auml;&szlig;igkeit, der aufgrund der Einwilligung bis zum
			Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Automatisierte
			Entscheidung im Einzelfall einschlie&szlig;lich Profiling<br/>
		Sie
			haben das Recht, nicht einer ausschlie&szlig;lich auf einer
			automatisierten Verarbeitung - einschlie&szlig;lich Profiling -
			beruhenden Entscheidung unterworfen zu werden, die Ihnen gegen&uuml;ber
			rechtliche Wirkung entfaltet oder Sie in &auml;hnlicher Weise
			erheblich beeintr&auml;chtigt. Dies gilt nicht, wenn die
			Entscheidung</font></font></p>
			<ol>
				<li/>
		<p ><font color="#2d333d"><font size="3" >f&uuml;r
				den Abschluss oder die Erf&uuml;llung eines Vertrags zwischen Ihnen
				und dem Verantwortlichen erforderlich ist,</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >aufgrund
				von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen
				der Verantwortliche unterliegt, zul&auml;ssig ist und diese
				Rechtsvorschriften angemessene Ma&szlig;nahmen zur Wahrung Ihrer
				Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten
				oder</font></font></p>
				<li/>
		<p ><font color="#2d333d"><font size="3" >mit
				Ihrer ausdr&uuml;cklichen Einwilligung erfolgt.</font></font></p>
			</ol>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Allerdings
			d&uuml;rfen diese Entscheidungen nicht auf besonderen Kategorien
			personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern
			nicht Art. 9 Abs. 2 lit. a oder b DSGVO gilt und angemessene
			Ma&szlig;nahmen zum Schutz der Rechte und Freiheiten sowie Ihrer
			berechtigten Interessen getroffen wurden.<br/>
		<br/>
		Hinsichtlich
			der in 1. und 3. genannten F&auml;lle trifft der Verantwortliche
			angemessene Ma&szlig;nahmen, um die Rechte und Freiheiten sowie Ihre
			berechtigten Interessen zu wahren, wozu mindestens das Recht auf
			Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
			auf Darlegung des eigenen Standpunkts und auf Anfechtung der
			Entscheidung geh&ouml;rt.<br/>
		 </font></font>
			</p>
		</ol>
		<p ><font color="#2d333d"><font size="3" >Recht
		auf Beschwerde bei einer Aufsichtsbeh&ouml;rde</font></font></p>
		<p ><font color="#2d333d"><font size="3" >Unbeschadet
		eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
		Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer
		Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres
		Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
		Versto&szlig;es, zu, wenn Sie der Ansicht sind, dass die Verarbeitung
		der Sie betreffenden personenbezogenen Daten gegen die DSGVO
		verst&ouml;&szlig;t.</font></font></p>
		<p ><br/>
		
		</p>
		<p ><font color="#2d333d"><font size="3" >Die
		Aufsichtsbeh&ouml;rde, bei der die Beschwerde eingereicht wurde,
		unterrichtet den Beschwerdef&uuml;hrer &uuml;ber den Stand und die
		Ergebnisse der Beschwerde einschlie&szlig;lich der M&ouml;glichkeit
		eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.</font></font></p>
		<p ><br/>
		
		</p>
		<p ><br/>
		
		</p>
		<h1 class="western" ><a name="_nh44a98fw8ni"></a>
		<font size="5">3.	 E-Mail-Kontakt</font></h1>
		<ol>
			<li/>
		<p><font color="#2d333d"><font size="3" >Beschreibung
			und Umfang der Datenverarbeitung<br/>
		Auf unserer Internetseite ist
			eine Kontaktaufnahme &uuml;ber die bereitgestellte Email-Adresse
			m&ouml;glich. In diesem Fall werden die mit der Email &uuml;bermittelten
			personenbezogenen Daten des Nutzers gespeichert.<br/>
		<br/>
		Die
			Daten werden ausschlie&szlig;lich f&uuml;r die Verarbeitung der
			Konversation verwendet.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Zweck
			der Datenverarbeitung<br/>
		Im Falle einer Kontaktaufnahme per Email
			liegt hieran auch das erforderliche berechtigte Interesse an der
			Verarbeitung der Daten.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Rechtsgrundlage
			f&uuml;r die Datenverarbeitung<br/>
		Rechtsgrundlage f&uuml;r die
			Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des
			Nutzers Art. 6 Abs. 1 lit. a DSGVO.<br/>
		<br/>
		Rechtsgrundlage f&uuml;r
			die Verarbeitung der Daten, die im Zuge einer &Uuml;bersendung einer
			Email &uuml;bermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt
			der Email-Kontakt auf den Abschluss eines Vertrages ab, so ist
			zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6
			Abs. 1 lit. b DSGVO.<br/>
		 </font></font>
			</p>
			<li/>
		<p ><font color="#2d333d"><font size="3" >Dauer
			der Speicherung<br/>
		Die Daten werden gel&ouml;scht, sobald sie f&uuml;r
			die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
			sind. F&uuml;r die personenbezogenen Daten, die per Email &uuml;bersandt
			wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit
			dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich
			aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene
			Sachverhalt abschlie&szlig;end gekl&auml;rt ist.<br/>
		<br/>
		Die
			w&auml;hrend des Absendevorgangs zus&auml;tzlich erhobenen
			personenbezogenen Daten werden sp&auml;testens nach einer Frist von
			sieben Tagen gel&ouml;scht.<br/>
		<br/>
		 </font></font>
			</p>
			<li/>
		<p><font color="#2d333d"><font size="3" >Widerspruchs-
			und Beseitigungsm&ouml;glichkeit<br/>
		Der Nutzer hat jederzeit die
			M&ouml;glichkeit, seine Einwilligung zur Verarbeitung der
			personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per Email
			Kontakt mit uns auf, so kann er der Speicherung seiner
			personenbezogenen Daten jederzeit widersprechen. In einem solchen
			Fall kann die Konversation nicht fortgef&uuml;hrt werden.<br/>
		<br/>
		Per
			E-Mail<br/>
		<br/>
		Alle personenbezogenen Daten, die im Zuge der
			Kontaktaufnahme gespeichert wurden, werden in diesem Fall gel&ouml;scht.<br/>
		
			</font></font><br/>
		<br/>
		
			</p>
		</ol>
		<p ><br/>
		
		</p>
		<p ><br/>
		
		</p>
		<p ><br/>
		
		</p>
		<p ><br/>
		
		</p>
		<p ><br/>
		
		</p>
		<p ><font color="#2d333d">
		</font><br/>
		
		</p>
		<h1 class="western"><a name="_zfml8w8nd3lz"></a>
		<font size="5">4. Hosting</font></h1>
		<p >
		<font color="#2d333d"><font size="3" >Die
		Webseite wird auf Servern von einem durch uns beauftragten
		Dienstleister gehostet.</font></font></p>
		<p >
		<br/>
		<br/>
		
		</p>
		<p >
		<font color="#2d333d"><font size="3" >F&uuml;r
		das Hosting ist die Dreher.Media UG (haftungsbeschr&auml;nkt)
		verantwortlich.</font></font></p>
		<p >
		<font color="#2d333d"><font size="3" >Alle
		weiteren Informationen sind auf der Unternehmenswebsite der
		Dreher.Media UG (haftungsbeschr&auml;nkt) auffindbar.</font></font></p>
		<p >
		<br/>
		<br/>
		
		</p>
		<p >
		<font color="#2d333d"><font size="3" >Ansprechpartner
		f&uuml;r R&uuml;ckfragen: </font></font><a href="mailto:contact@dreher-media.de"><font color="#1155cc"><font size="3" ><u>contact@dreher-media.de</u></font></font></a></p>
		<p >
		<br/>
		<br/>
		
		</p>
		<h1 class="western"><a name="_5tv6tqsy0ioy"></a>
		<font size="5">5. Verwendete Plugins</font></h1>
		<p align="justify" >
		<font color="#333333"><font size="3" >Datenschutzerkl&auml;rung
		f&uuml;r die Nutzung von Facebook-Plugins (Like-Button)</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Auf
		unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter
		Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA,
		integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo
		oder dem &ldquo;Like-Button&rdquo; (&ldquo;Gef&auml;llt mir&rdquo;)
		auf unserer Seite. Eine &Uuml;bersicht &uuml;ber die Facebook-Plugins
		finden Sie hier: http://developers.facebook.com/docs/plugins/.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Wenn Sie
		unsere Seiten besuchen, wird &uuml;ber das Plugin eine direkte
		Verbindung zwischen Ihrem Browser und dem Facebook-Server
		hergestellt. Facebook erh&auml;lt dadurch die Information, dass Sie
		mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den
		Facebook &ldquo;Like-Button&rdquo; anklicken w&auml;hrend Sie in
		Ihrem Facebook-Account eingeloggt sind, k&ouml;nnen Sie die Inhalte
		unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann
		Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir
		weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
		vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch
		Facebook erhalten. Weitere Informationen hierzu finden Sie in der
		Datenschutzerkl&auml;rung von Facebook unter
		http://de-de.facebook.com/policy.php.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Wenn Sie
		nicht w&uuml;nschen, dass Facebook den Besuch unserer Seiten Ihrem
		Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
		Facebook-Benutzerkonto aus.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Datenschutzerkl&auml;rung
		f&uuml;r die Nutzung von Google+</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Unsere
		Seiten nutzen Funktionen von Google+. Anbieter ist die Google Inc.,
		1600 Amphitheatre Parkway Mountain View, CA 94043, USA.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Erfassung
		und Weitergabe von Informationen: Mithilfe der Google+-Schaltfl&auml;che
		k&ouml;nnen Sie Informationen weltweit ver&ouml;ffentlichen. &Uuml;ber
		die Google+-Schaltfl&auml;che erhalten Sie und andere Nutzer
		personalisierte Inhalte von Google und unseren Partnern. Google
		speichert sowohl die Information, dass Sie f&uuml;r einen Inhalt +1
		gegeben haben, als auch Informationen &uuml;ber die Seite, die Sie
		beim Klicken auf +1 angesehen haben. Ihre +1 k&ouml;nnen als Hinweise
		zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie
		etwa in Suchergebnissen oder in Ihrem Google-Profil, oder an anderen
		Stellen auf Websites und Anzeigen im Internet eingeblendet werden.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Google
		zeichnet Informationen &uuml;ber Ihre +1-Aktivit&auml;ten auf, um die
		Google-Dienste f&uuml;r Sie und andere zu verbessern. Um die
		Google+-Schaltfl&auml;che verwenden zu k&ouml;nnen, ben&ouml;tigen
		Sie ein weltweit sichtbares, &ouml;ffentliches Google-Profil, das
		zumindest den f&uuml;r das Profil gew&auml;hlten Namen enthalten
		muss. Dieser Name wird in allen Google-Diensten verwendet. In manchen
		F&auml;llen kann dieser Name auch einen anderen Namen ersetzen, den
		Sie beim Teilen von Inhalten &uuml;ber Ihr Google-Konto verwendet
		haben. Die Identit&auml;t Ihres Google-Profils kann Nutzern angezeigt
		werden, die Ihre E-Mail-Adresse kennen oder &uuml;ber andere
		identifizierende Informationen von Ihnen verf&uuml;gen.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Verwendung
		der erfassten Informationen: Neben den oben erl&auml;uterten
		Verwendungszwecken werden die von Ihnen bereitgestellten
		Informationen gem&auml;&szlig; den geltenden
		Google-Datenschutzbestimmungen genutzt. Google ver&ouml;ffentlicht
		m&ouml;glicherweise zusammengefasste Statistiken &uuml;ber die
		+1-Aktivit&auml;ten der Nutzer bzw. gibt diese an Nutzer und Partner
		weiter, wie etwa Publisher, Inserenten oder verbundene Websites.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Datenschutzerkl&auml;rung
		f&uuml;r die Nutzung von Instagram</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Auf
		unseren Seiten sind Funktionen des Dienstes Instagram eingebunden.
		Diese Funktionen werden angeboten durch die Instagram Inc., 1601
		Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem
		Instagram-Account eingeloggt sind k&ouml;nnen Sie durch Anklicken des
		Instagram-Buttons die Inhalte unserer Seiten mit Ihrem
		Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer
		Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir
		als Anbieter der Seiten keine Kenntnis vom Inhalt der u&#776;bermittelten
		Daten sowie deren Nutzung durch Instagram erhalten.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Weitere
		Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von
		Instagram: http://instagram.com/about/legal/privacy/</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Datenschutzerkl&auml;rung
		f&uuml;r die Nutzung von YouTube</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Unsere
		Website nutzt Plugins der von Google betriebenen Seite YouTube.
		Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San
		Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem YouTube-Plugin
		ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern
		von YouTube hergestellt. Dabei wird dem Youtube-Server mitgeteilt,
		welche unserer Seiten Sie besucht haben.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Wenn Sie
		in Ihrem YouTube-Account eingeloggt sind erm&ouml;glichen Sie
		YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
		zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
		YouTube-Account ausloggen.</font></font></p>
		<p align="justify" >
		<font color="#333333"><font size="3" >Weitere
		Informationen zum Umgang von Nutzerdaten finden Sie in der
		Datenschutzerkl&auml;rung von YouTube unter:
		</font></font><a href="https://www.google.de/intl/de/policies/privacy"><font color="#1155cc"><font size="3" ><u>https://www.google.de/intl/de/policies/privacy</u></font></font></a><font color="#333333"><font size="3" >
		</font></font>
		</p>
		<p >
		<br/>
		<br/>
		
		</p>
		<p > 
		</p>
		<p ><br/>
		
		</p>
		<p ><br/>
		
		</p>
</div>

    );
}

export default Privacy;