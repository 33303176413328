
import React from 'react';
import { withNamespaces } from 'react-i18next';


function FooterBlack({ language ,t }) {
	return (
		<footer className="margin-footer footer-black">
			<div className="footer-section">
				<div className="item item-1">
				<a href="/imprint">{t('imprint')}</a>
					<span>,&nbsp;</span>
					<a href="/privacy">{t('data & privacy')}</a>
				</div>
				<div className="item item-2">
					<p>©2021 by Laura Kießkalt</p>
				</div>
				<div className="item item-3">
				<a>{t('created by')}</a>
					<a href="https://www.dreher-media.de/" target="_blank" rel="noopener noreferrer">
						<img src="img/Schriftzug-dm-black.png"alt="logo dreher.media" />
					</a>
				</div>
			</div>
		</footer>
	);
}

export default withNamespaces()(FooterBlack);
